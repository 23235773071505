<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center my-10 px-8 px-lg-10">
                    <div class="col-xl-12 col-xxl-10 invoicepage">
                        <!--begin: Wizard Form-->
                        <form class="form" id="kt_form printSection">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                 <el-row>
                                    <el-col :span="24" align="right">
                                      <el-dropdown  @command="handleActions($event)" size="medium" split-button class="action_btn">
                                        Actions
                                        <el-dropdown-menu slot="dropdown" class="action_dropdown">
                                          <el-dropdown-item :command="1" >Print</el-dropdown-item>
                                          <el-dropdown-item :command="2" >Download</el-dropdown-item>
                                          <el-dropdown-item :command="3" >Send Email</el-dropdown-item>
                                        </el-dropdown-menu>
                                      </el-dropdown>
                                    </el-col>
                                  </el-row>
                                  <el-row>
                                        <el-col :span="12" align="left">
                                            <img src="https://ctshub.s3.ca-central-1.amazonaws.com/images/cts-logo.png" />
                                        </el-col>
                                        <el-col :span="12" align="right">
                                             <b >
                                        <label class="mt-10">733 Progress Ave, Scarborough, ON M1H 2W7, Canada.</label><br>
                                        <label>Phone: +1 416-840-4538</label><br>
                                        <label>Fax: +1 416-840-4538</label><br>
                                        <label>Email: Info@ctsbuildingsupplies.com</label>
                                        </b>
                                        <br>
                                        </el-col>
                                  </el-row>
                                  <hr class="invoice-line">
                                  <el-row>
                                      <el-col :span="6" align="left">
                                        <img src="@/assets/images/barcode.jpg" style=" width: 200px; height: 45px;"/>
                                      </el-col>
                                      <el-col :span="12" align="left">
                                      <h2 style="font-family: Arial;text-align: center;"><b>PACKING SLIP</b></h2>
                                      </el-col>
                                      <el-col :span="6" align="left">
                                      </el-col>                                      
                                  </el-row>
                                  <el-row style="margin-top:5px; margin-bottom:5px;">
                                       <el-col :span="12"  align="left">
                                           <span><b>PACKINGSLIP #</b></span><span><b> {{view.order_number}}</b></span>
                                       </el-col>
                                       <el-col :span="12"  align="right">
                                            <div><span><b>SHIP DATE :</b></span><span><b> Nov-1-2020</b></span></div>
                                            <div><span><b>CUSTOMER ID :</b></span><span><b> AB152</b></span></div>
                                             <div><span><b>CUSTOMER P.O#</b></span><span><b> 53153153</b></span></div>
                                       </el-col>
                                  </el-row>
                                   <el-row class="add-info">
                                      <el-col :span="8" >
                                          <div class="billing-adr" style="padding-left:10px;">BILL TO :</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.address_line_1">{{view.billing_address_order.address_line_1 +'  '+view.billing_address_order.address_line_2}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.city_details">{{view.billing_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.billing_address_order.state_details">{{view.billing_address_order.state_details.state_name+' - '+view.billing_address_order.post_code}}</div>
                                      </el-col>
                                       <el-col :span="8" align="">
                                            .
                                      </el-col>
                                       <el-col :span="8" align="left">                                          
                                         <div class="billing-adr" style="padding-left:10px;">SHIP TO :</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.address_line_1">{{view.shipping_address_order.address_line_1 +'  '+view.shipping_address_order.address_line_2}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.city_details">{{view.shipping_address_order.city_details.city_name}}</div>
                                          <div style="padding: 3px;" v-if="view.shipping_address_order.state_details">{{view.shipping_address_order.state_details.state_name+' - '+view.shipping_address_order.post_code}}</div>
                                      </el-col>
                                    </el-row>
                                      <div class="row">
                                          <div class="col-xl-12 ">
                                      

                                        <table search-options="" id="website-table" role="table" aria-busy="false" aria-colcount="14" class="table b-table tab-row-hover" >
                                            <tbody role="rowgroup">
                                                <tr class="billing-cl2 text-center invoice-header" >
                                                    <th aria-colindex="1" role="cell"><div >ITEM #</div></th>
                                                    <th aria-colindex="2" role="cell"><div >DESCRIPTION</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SHIP VIA</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Quantity</div></th>
                                                    <th aria-colindex="2" role="cell"><div >Type</div></th>
                                                    <th aria-colindex="2" role="cell"><div >SHIPPED</div></th>
                                                     <th aria-colindex="2" role="cell"><div >BACKORDER</div></th>
                                                </tr>
                                                <tr class="billing-cl3" v-for="item in view.order_items" :key="item.id">
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.product_item[0].sku}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.product_item[0].description}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{view.ship_via}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">{{item.quantity}}</div></td>
                                                    <td aria-colindex="2" role="cell" class="text-center"><div class="padding-10">{{view.type}}</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">500</div></td>
                                                    <td aria-colindex="1" role="cell" class="text-center"><div class="padding-10">300</div></td>
                                                </tr>
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                      </div>
                                      <el-row> 
                                          <el-col :span="24">
                                                 <div class="padding-10"><b><u>Other Comments or Special Instructions</u></b></div>
                                                 <div class="padding-10"></div>
                                                 <div class="padding-10" style="margin-top:40px;"><b>Total Weight : </b></div>
                                          </el-col>
                                      </el-row>
                                      <hr class="invoice-line">
                                       <el-row>
                                      
                                      <el-col :span="24" align="left">
                                      <div style="text-align: center;"><b>If you have any questions about this order, please contact</b></div>
                                      <div style="text-align: center; font-size:15px;"><b>EMAIL: INFO@CTSBUILDINGSUPPLIES.COM</b></div>
                                      </el-col>
                                       </el-row>                                     
                              
                               </div>
                            
                        </form>
                        <!--end: Settings Form-->
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import moment from 'moment';
import {getCorder, downloadPdf} from "@/api/order";
import SendEmail from '@/components/SendEmail'
export default {
  name: 'view-order',
  components: {},
   data() {
    return {
      loading: false,
      view:[],
      viewitem:[],      
      view_type: 'packingslip'
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Order Management", route: "/order/list" },
      { title: "View Order" }
    ]);
  },
  created() {
    this.fetchData(1)   
  },
  
   methods: {
   fetchData() {
      this.loading = true
      getCorder(this.$route.params.order_id).then(response => {
        console.log(response);
        this.view = response.data.data[0]      
        this.loading = false
      });
    },
     printWindow: function () {		
	     window.print();
    },
    format_date(value){
        if (value) {
        return moment(String(value)).format('DD-MM-YYYY')
        }
    }, 
     handleActions(evt){
      if (evt === 1){
        this.printWindow();
      }else if (evt === 2){
        this.loading=true;
        downloadPdf(this.$route.params.order_id, this.view_type).then(response => {
          //console.log(response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'packingslip.pdf');
          document.body.appendChild(link);
          link.click();
          this.loading = false;
        }).catch(err => {
          console.log(err);
          this.loading = false;
        });
      }else if (evt === 3){
        let customer = this.view.customer;
        let order_id = this.view.id;
        let type = this.view_type;

        this.$modal.show(SendEmail, {customer, type, order_id}, 
          { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
      }
    }    
   }
   
};
</script>

<style >
    hr.invoice-line{
        border-top: 1px solid black;
        width: 100%;
    }
    .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         padding:3px;
        border:1px solid #366092;
    }
    .invoice-header{
        background-color:#366092; 
        color: #ffffff;
    }
    #website-table th, #website-table td {
      border: 1px solid #366092; padding: 5px;
    }
    .tab-row-hover {
        border: 1px solid #366092;
    }
    .add-info{
      margin-bottom: 20px;
    }
    @media print {
       
        .print-section, .download-btn, .subheader, .action_btn, .action_dropdown {
            visibility: hidden;
            -webkit-print-color-adjust: exact;
        }
         .billing-adr, .shipping-adr{
         font-size: 15px; 
         background-color:#366092; 
         color: #ffffff;
         -webkit-print-color-adjust: exact;
        
    }

    .table th { 
        background-color: #366092 !important; 
         color: #ffffff !important;
         -webkit-print-color-adjust: exact;
    } 
   
    

}
 
</style>